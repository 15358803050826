var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("main", {
    staticClass: "recommended-news-card"
  }, [_c("h1", {
    staticClass: "news-info"
  }, [_c("h3", {
    staticClass: "news-title ellipsis-3"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c("img", {
    staticClass: "news-img",
    attrs: {
      src: _vm.coverurl,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "divider-line clearfix"
  }), _c("section", {
    staticClass: "news-card-footer"
  }, [_c("img", {
    staticClass: "avatar",
    attrs: {
      src: _vm.sourceurl,
      alt: ""
    }
  }), _vm.source ? _c("span", {
    staticClass: "nft-source ellipsis-1"
  }, [_vm._v(_vm._s(_vm.source))]) : _vm._e(), _vm.collecName ? _c("div", {
    staticClass: "club-label ellipsis-1"
  }, [_vm._v(" " + _vm._s(_vm.collecName) + " ")]) : _vm._e(), _c("span", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm._f("formatDateToAgo")(_vm.publishTime)))])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };