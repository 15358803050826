import "core-js/modules/es.number.constructor.js";
export default {
  name: "RecommendedNewsCard",
  props: {
    title: {
      type: String,
      default: ""
    },
    coverurl: {
      type: String,
      default: ""
    },
    collectCoverurl: {
      type: String,
      default: ""
    },
    collectName: {
      type: String,
      default: ""
    },
    source: {
      type: String,
      default: ""
    },
    sourceurl: {
      type: String,
      default: ""
    },
    publishTime: {
      type: [String, Number],
      default: ""
    },
    collecName: {
      type: String,
      default: ""
    }
  },
  handleClick: function handleClick(ev) {
    this.$emit("click", ev);
  }
};