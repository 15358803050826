import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "release"
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "新闻标题"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: true,
          message: "请输入新闻标题"
        }]
      }],
      expression: "[\n          'title',\n          { rules: [{ required: true, message: '请输入新闻标题' }] },\n        ]"
    }],
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    staticClass: "itemSource",
    attrs: {
      label: "新闻来源媒体"
    }
  }, [_c("a-radio-group", {
    on: {
      change: _vm.changeSource
    },
    model: {
      value: _vm.items.sourcetype,
      callback: function callback($$v) {
        _vm.$set(_vm.items, "sourcetype", $$v);
      },
      expression: "items.sourcetype"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("推荐")]), _c("a-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: function change($event) {
        return _vm.$forceUpdate();
      }
    },
    model: {
      value: _vm.items.recommendSource,
      callback: function callback($$v) {
        _vm.$set(_vm.items, "recommendSource", $$v);
      },
      expression: "items.recommendSource"
    }
  }, _vm._l(_vm.newsconfigList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.source) + " ")]);
  }), 1), _c("a-button", {
    attrs: {
      type: "link"
    },
    on: {
      click: _vm.goDcmedia
    }
  }, [_vm._v("管理")])], 1), _c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("自定义")]), _c("a-input", {
    model: {
      value: _vm.items.customSource,
      callback: function callback($$v) {
        _vm.$set(_vm.items, "customSource", $$v);
      },
      expression: "items.customSource"
    }
  })], 1)], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "新闻概要"
    }
  }, [_c("a-textarea", {
    attrs: {
      autosize: true
    },
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    },
    model: {
      value: _vm.items.desc,
      callback: function callback($$v) {
        _vm.$set(_vm.items, "desc", $$v);
      },
      expression: "items.desc"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "新闻主文"
    }
  }, [_c("quill-editor", {
    ref: "myQuillEditor",
    staticClass: "editor",
    attrs: {
      options: _vm.editorOption
    },
    on: {
      change: function change($event) {
        return _vm.onEditorChange($event);
      }
    },
    model: {
      value: _vm.items.content,
      callback: function callback($$v) {
        _vm.$set(_vm.items, "content", $$v);
      },
      expression: "items.content"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "新闻来源链接"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["url", {
        rules: [{
          required: true,
          message: "请输入新闻来源链接"
        }]
      }],
      expression: "[\n          'url',\n          { rules: [{ required: true, message: '请输入新闻来源链接' }] },\n        ]"
    }],
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "关联NFT项目"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["collectionids", {
        rules: [{
          required: false,
          message: "请选择"
        }]
      }],
      expression: "[\n          'collectionids',\n          {\n            rules: [{ required: false, message: '请选择' }],\n          },\n        ]"
    }],
    attrs: {
      allowClear: "",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择合集",
      "option-label-prop": "label"
    }
  }, _vm._l(_vm.collectdata, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id + "",
        label: item.name
      }
    }, [_c("span", {
      attrs: {
        role: "img",
        "aria-label": "item.name"
      }
    }, [_c("img", {
      staticStyle: {
        width: "20px",
        height: "20px"
      },
      attrs: {
        src: item.icon_url,
        alt: ""
      }
    })]), _vm._v(" " + _vm._s(item.name) + " "), _c("a", {
      staticClass: "share",
      staticStyle: {
        float: "right"
      },
      attrs: {
        href: "https://test.knowherex.com/dataIndex/overview/".concat(item.id),
        target: "_blank"
      }
    }, [_c("a-icon", {
      attrs: {
        type: "share-alt"
      }
    })], 1)]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "推送首页"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["showinfrontpage", {
        rules: [{
          required: true,
          message: "请选择是否推送首页"
        }]
      }],
      expression: "[\n          'showinfrontpage',\n          { rules: [{ required: true, message: '请选择是否推送首页' }] },\n        ]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, _vm._l(_vm.ispush, function (item) {
    return _c("a-select-option", {
      key: item.name,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "新闻分类"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["categoryid", {
        rules: [{
          required: true,
          message: "请选择新闻分类"
        }]
      }],
      expression: "[\n          'categoryid',\n          { rules: [{ required: true, message: '请选择新闻分类' }] },\n        ]"
    }],
    attrs: {
      placeholder: "请选择",
      type: "number"
    }
  }, _vm._l(_vm.categorydata, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "新闻标签"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["labels", {
        rules: [{
          required: true,
          message: "请选择新闻标签"
        }]
      }],
      expression: "[\n          'labels',\n          { rules: [{ required: true, message: '请选择新闻标签' }] },\n        ]"
    }],
    attrs: {
      mode: "multiple",
      placeholder: "请选择"
    }
  }, _vm._l(_vm.newslabelList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.queryid
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "跳转方式"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["linktype", {
        rules: [{
          required: true,
          message: "请选择跳转方式"
        }]
      }],
      expression: "[\n          'linktype',\n          { rules: [{ required: true, message: '请选择跳转方式' }] },\n        ]"
    }],
    attrs: {
      placeholder: "请选择"
    }
  }, [_c("a-select-option", {
    attrs: {
      value: 0
    }
  }, [_vm._v("留存本站")]), _c("a-select-option", {
    attrs: {
      value: 1
    }
  }, [_vm._v("直接来源")])], 1)], 1), _c("a-form-item", {
    attrs: {
      label: "展示时间"
    }
  }, [_c("a-date-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["createtime", {
        rules: [{
          required: true,
          message: "请选择展示时间"
        }]
      }],
      expression: "[\n          'createtime',\n          { rules: [{ required: true, message: '请选择展示时间' }] },\n        ]"
    }],
    attrs: {
      "show-time": "",
      placeholder: "请选择展示时间"
    }
  })], 1), _c("a-form-item", {
    staticClass: "coverImg",
    attrs: {
      label: "封面"
    }
  }, [_c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["coverurl", {
        rules: [{
          required: true,
          message: "请上传图片"
        }],
        initialValue: ""
      }],
      expression: "[\n          'coverurl',\n          {\n            rules: [{ required: true, message: '请上传图片' }],\n            initialValue: '',\n          },\n        ]"
    }],
    attrs: {
      force: true,
      showTips: false,
      max: 1,
      accept: ".jpg, .png",
      listType: "picture-card"
    },
    on: {
      previewImg: _vm.previewImg
    }
  }), _c("div", {
    staticClass: "tips"
  }, [_c("div", [_vm._v("建议尺寸：740*740")]), _c("div", [_vm._v("大小: <3MB")]), _c("div", [_vm._v("格式：JPG,PNG")])])], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_vm.items.status != 2 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoManagement:btn:save"],
      expression: "['infoManagement:btn:save']"
    }],
    attrs: {
      size: "large"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd(1);
      }
    }
  }, [_vm._v("草稿保存")]) : _vm._e(), _vm.items.status != 3 ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoManagement:btn:issue"],
      expression: "['infoManagement:btn:issue']"
    }],
    staticClass: "btn_l",
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("发布 ")]) : _vm._e(), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoManagement:btn:preview"],
      expression: "['infoManagement:btn:preview']"
    }],
    staticClass: "btn_l",
    staticStyle: {
      "margin-left": "260px"
    },
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.handleStatus
    }
  }, [_vm._v("预览 ")])], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.handleCancel
    }
  }), _c("KwDialog", {
    ref: "kwdialog",
    attrs: {
      width: "80vw",
      height: "90vh",
      showFullscreen: "",
      showFooter: false
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("PreviewNews", {
    attrs: {
      recommendNews: _vm.recommendNews,
      newsDetail: _vm.newsDetail
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };