import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "kw-newsdetails-container"
  }, [_c("main", {
    staticClass: "kw-newsdetails-main"
  }, [_c("h1", {
    staticClass: "news-title"
  }, [_vm._v(_vm._s(_vm.newsDetail.title))]), _c("div", {
    staticClass: "anthor"
  }, [_vm.newsDetail.source ? [_vm.newsDetail.sourceurl ? _c("img", {
    staticClass: "kw-avatar",
    attrs: {
      src: _vm.newsDetail.sourceurl,
      alt: ""
    }
  }) : _vm._e(), _c("span", {
    staticClass: "source"
  }, [_vm._v(_vm._s(_vm.newsDetail.source))])] : _vm._e(), _c("span", {
    staticClass: "time"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.newsDetail.publishtime)))]), _c("div", {
    staticClass: "tag-ctagegory-wrap"
  }, [_vm.newsDetail.ctagegoryiconurl ? _c("div", {
    staticClass: "tag-ctagegory-icon"
  }, [_c("img", {
    attrs: {
      src: _vm.newsDetail.ctagegoryiconurl,
      alt: ""
    }
  })]) : _vm._e(), _c("span", [_vm._v(_vm._s(_vm.newsDetail.categoryname))])]), _vm.newsDetail.selected ? _c("div", {
    staticClass: "kw-tag"
  }, [_vm._v("Editor's Pick")]) : _vm._e(), _vm._l(_vm.newsDetail.labels, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "kw-tag"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  })], 2), _c("section", {
    staticClass: "kw-newsdetails-content"
  }, [_c("aside", {
    staticClass: "left"
  }, [_c("div", {
    staticClass: "detail",
    domProps: {
      innerHTML: _vm._s(_vm.newsDetail.content)
    }
  })]), _vm.collectDetail.length || _vm.recommendNews.length ? _c("aside", {
    staticClass: "right"
  }, [_vm.collectDetail && _vm.collectDetail.length ? _c("h3", {
    staticClass: "subtitle"
  }, [_vm._v(" Related Project Page ")]) : _vm._e(), _vm._l(_vm.collectDetail, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "card-wrap"
    }, [_c("div", {
      staticClass: "card-top"
    }, [_c("div", {
      staticClass: "card-top-left"
    }, [_c("img", {
      attrs: {
        src: item.icon_url,
        alt: ""
      }
    }), _c("span", {
      staticClass: "ellipsis-1"
    }, [_vm._v(_vm._s(item.name))])])]), _c("div", {
      staticClass: "card-desc"
    }, [_vm._v(" " + _vm._s(item.desc) + " ")])]);
  }), _vm.recommendNews.length ? _c("h3", {
    staticClass: "subtitle"
  }, [_vm._v(" Recommended News ")]) : _vm._e(), _vm._l(_vm.recommendNews, function (item) {
    return [_c("RecommendedNewsCard", {
      key: item.slug,
      staticClass: "side-news-card",
      attrs: {
        title: item.title,
        coverurl: item.coverurl,
        source: item.source,
        sourceurl: item.sourceurl,
        "publish-time": item.publishtime,
        "collec-name": item.collections ? item.collections[0].name : ""
      }
    })];
  })], 2) : _vm._e()])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };